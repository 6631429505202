<template>
  <div class="video-wrapper">
    <vue-vimeo-player
      class="video-frame"
      :ref="`player_${id}`"
      :video-id="videoId"
      @ready="onReady"
      @play="onPlay"
      @seeked="onSeek"
      @pause="onPause"
      @ended="onEnded"
      @timeupdate="onTimeUpdate"
    >
    </vue-vimeo-player>
    <div v-if="hasCover" :id="`frameCover_${id}`" class="iframe-cover" @click="triggerPlay"></div>
  </div>
</template>
<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { VIDEO_LOG_STATUS as viewStatus } from '@/constants/component.constants.js';
import Content from '@/services/Api/content';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'BrandVimeoPlayer',
  mixins: [gtmUtils],
  props: {
    logger: {
      type: [String, Object],
    },
    data: {
      type: Object,
      required: true,
    },
    id: {
      default: 'vimeo_id',
    },
    hasCover: {
      type: Boolean,
    },
    isChangedSlider: {
      type: Boolean,
    },
  },
  components: {
    vueVimeoPlayer,
  },
  data() {
    return {
      logTimer: null,
      isViewed: this.data.viewStatus == viewStatus.viewed,
      isStarted: false,
      isSeeked: false,
      skippedTime: 0,
      lastInterval: 0,
      effectiveWatched: 0,
      logFlag: [],
      frameCss: {
        height: '100%',
        width: '100%',
        top: '0',
        left: '0',
        position: 'absolute',
      },
    };
  },
  watch: {
    isChangedSlider: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.$refs[`player_${this.id}`].pause();
        }
      },
    },
  },
  computed: {
    videoId() {
      if (!this.data.embedVideo) return '';

      return this.data.embedVideo.substring(this.data.embedVideo.lastIndexOf('/') + 1);
    },
  },
  methods: {
    triggerPlay() {
      this.$refs[`player_${this.id}`].play();
    },
    setEmitData(video, end) {
      return {
        percent: (this.effectiveWatched / video.data.duration) * 100,
        position: event.data.data.seconds * 1000,
        viewStatus: end ? '2' : '1',
        isViewed: end,
      };
    },
    onReady(e) {
      Object.keys(this.frameCss).every(item => (e.element.style[item] = this.frameCss[item]));
      if (
        this.logger &&
        this.data.percentWatched &&
        this.data.playbackPosition &&
        this.data.playbackPosition > 0
      ) {
        if (this.data.percentWatched < 96) {
          this.$refs[`player_${this.id}`].player.getDuration().then(duration => {
            let playPos = this.data.playbackPosition / 1000;
            let percent = this.data.percentWatched;
            let startPos =
              percent >= Math.round((playPos / duration) * 100) ? Math.floor(playPos) : 0;
            this.$refs[`player_${this.id}`].player.setCurrentTime(startPos);
            this.lastInterval = this.effectiveWatched = startPos;
          });
        }
      }
      this.$emit('ready');
    },
    onSeek() {
      this.isSeeked = true;
      this.dataLayerLog('Skip');
    },
    onPlay() {
      this.$emit('play');
      if (this.hasCover) document.getElementById(`frameCover_${this.id}`).style.display = 'none';

      if (this.logger && !this.isViewed && !this.isStarted) {
        this.logTimer = new Date().getTime();
        Content.setVideoLog(
          this.data.contentId || this.data.videoId,
          viewStatus.played,
          this.effectiveWatched / event.data.data.duration,
          event.data.data.seconds,
          true,
        );
      }
      this.isStarted = true;
      setTimeout(() => {
        !this.isSeeked && this.dataLayerLog('Play');
        this.isSeeked = false;
      }, 200);
    },
    onEnded() {
      // If more than 90% of the video is watched, set the viewed variable to true
      this.isViewed = this.effectiveWatched >= event.data.data.duration * 0.9 ? true : false;
      this.$emit('end', this.setEmitData(event.data, this.isViewed));
      this.dataLayerLog('end');
      if (this.hasCover) document.getElementById(`frameCover_${this.id}`).style.display = '';
      if (this.logger) {
        Content.setVideoLog(
          this.data.contentId || this.data.videoId,
          this.isViewed ? viewStatus.viewed : viewStatus.played,
          this.effectiveWatched / event.data.data.duration,
          event.data.data.seconds,
          false,
        );
      }
    },
    onPause() {
      this.$emit('pause');
      setTimeout(() => {
        this.dataLayerLog('Pause');
      }, 300);
      if (this.hasCover) document.getElementById(`frameCover_${this.id}`).style.display = '';
    },
    dataLayerLog(action, percent) {
      let log = '';
      if (action === 'log') {
        if (percent >= 0.1 && percent < 0.25 && !this.logFlag.includes(10)) {
          this.logFlag.push(10);
          log = 10;
        } else if (percent >= 0.25 && percent < 0.5 && !this.logFlag.includes(25)) {
          this.logFlag.push(25);
          log = 25;
        } else if (percent >= 0.5 && percent < 0.75 && !this.logFlag.includes(50)) {
          this.logFlag.push(50);
          log = 50;
        } else if (percent >= 0.75 && percent < 0.9 && !this.logFlag.includes(75)) {
          this.logFlag.push(75);
          log = 75;
        } else if (percent >= 0.9 && !this.logFlag.includes(90)) {
          this.logFlag.push(90);
          log = 90;
        }

        if (log) {
          this.pushDataLayerEvent('video', {
            name: this.data.title || 'Bilinmiyor',
            action: `%${log}`,
            url: this.data.embedVideo || '',
          });
        }
      } else if (action === 'end') {
        this.pushDataLayerEvent('video', {
          name: this.data.title || 'Bilinmiyor',
          action: 'Watch to End',
          url: this.data.embedVideo || '',
        });
      } else if (action === 'Pause') {
        if (!this.isViewed) {
          this.pushDataLayerEvent('video', {
            name: this.data.title || 'Bilinmiyor',
            action: 'Pause',
            url: this.data.embedVideo || '',
          });
        }
      } else {
        this.pushDataLayerEvent('video', {
          name: this.data.title || 'Bilinmiyor',
          action: action,
          url: this.data.embedVideo || '',
        });
      }
    },
    onTimeUpdate() {
      this.dataLayerLog('log', this.effectiveWatched / event.data.data.duration);
      if (
        this.logger &&
        this.logger.interval &&
        !this.isViewed &&
        this.logTimer + this.logger.interval * 1000 < new Date().getTime()
      ) {
        // If the video is skipped, the change between intervals are greater than 5 seconds
        // Did not use the onSeek method because onTimeUpdate and onSeek are fired simultaneously
        if (event.data.data.seconds - this.lastInterval >= 6) {
          this.skippedTime += event.data.data.seconds - this.lastInterval;
        } else if (event.data.data.seconds < this.lastInterval) {
          this.skippedTime -= this.lastInterval - event.data.data.seconds;
        }
        this.effectiveWatched =
          event.data.data.seconds - this.skippedTime <= event.data.data.duration
            ? event.data.data.seconds - this.skippedTime
            : event.data.data.duration;
        this.lastInterval = event.data.data.seconds;
        this.$emit('timeUpdate', this.setEmitData(event.data));
        this.logTimer = new Date().getTime();
        Content.setVideoLog(
          this.data.contentId || this.data.videoId,
          viewStatus.played,
          this.effectiveWatched / event.data.data.duration,
          event.data.data.seconds,
          false,
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video-wrapper {
  .video-frame {
    position: relative;
    display: flex;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    overflow: hidden;

    /deep/ div {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
  }
  .iframe-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
